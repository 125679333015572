import { css } from 'styled-components'
import THEME from 'constants/theme'
const scrollbar = css`
	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		border-radius: 20px;
		background: rgba(0, 0, 0, 0.1);
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.3);
		transition: ${THEME.transition.md};
	}
`

export default scrollbar
