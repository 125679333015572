import styled from 'styled-components'
import tm from 'themmer'
import { focusOutline } from 'utils/style/prop'

const ExternalLink = styled.a.attrs({ target: '_blank', rel: 'noopener' })`
	position: relative;
	cursor: pointer;
	color: ${tm`color.pm`};
	text-decoration: none;
	font-weight: bold;

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 4px;
		bottom: -0.4rem;
		left: 0;
		background-image: ${tm`gradient.pm`};
		visibility: hidden;
		transform: scaleX(0);
		transition: ${tm`transition.lg`};
	}

	&:hover {
		&:before {
			visibility: visible;
			transform: scaleX(1);
		}
	}

	${focusOutline};
`

export default ExternalLink
