import { css } from 'styled-components'

import tm from 'themmer'
// Check if a prop is true and apply consequently
export const ternary = {
	centered: css`
		text-align: ${p => (p.centered ? 'center' : 'left')};
	`
}

// Add hovering effect, and initialize the property modified BEFORE hovering
export const hover = {
	shadow: (baseShadow = 'sm', shadow = 'md') => css`
		box-shadow: ${p => p.theme.shadow[baseShadow]};
		&:hover {
			box-shadow: ${p => p.theme.shadow[shadow]};
		}
	`,
	transition: (inTransition = 'md', outTransition = 'md') => css`
		transition: ${p => p.theme.transition[outTransition]};
		&:hover {
			transition: ${p => p.theme.transition[inTransition]};
		}
	`
}

export const focusOutline = css`
	&:focus {
		outline: 2px solid ${tm`color.pm`};
	}
`
