import React from 'react'
import styled from 'styled-components'
import tm from 'themmer'

import PAGES from 'constants/pages'
import { MenuItem } from 'atoms'
const S = {}

export const HEADER_HEIGHT = '6rem'
S.Wrapper = styled.header`
	height: ${HEADER_HEIGHT};
	width: 100%;
	transition: ${tm`transition.md`};
	background-image: ${tm`gradient.pm`};
	clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
`
S.Navigation = styled.nav`
	height: 100%;
`

S.MenuItemsList = styled.ul`
	list-style: none;
	padding: 0;
	height: 100%;
	margin: 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
`

const Header = () => (
	<S.Wrapper>
		<S.Navigation>
			<S.MenuItemsList>
				<MenuItem lg minContent to={PAGES.index.path}>
					{PAGES.index.label}
				</MenuItem>
				<MenuItem to={PAGES.portfolio.path}>{PAGES.portfolio.label}</MenuItem>
				<MenuItem to={PAGES.contact.path}>{PAGES.contact.label}</MenuItem>
			</S.MenuItemsList>
		</S.Navigation>
	</S.Wrapper>
)

export default Header
