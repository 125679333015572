import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import P from 'prop-types'

import { firstEdge } from 'utils/gatsby'
import flex from 'utils/style/flex'
import { Headline } from 'atoms'
import { SeparatedParagraphs } from 'molecules'
import { ProfilPhoto } from 'organisms'

const S = {}
S.Wrapper = styled.section`
	${flex.col.center};
	justify-content: space-around;
`

S.PhotoHeadlineWrapper = styled.div`
	${flex.col.center};
	align-items: stretch;
	${Headline} {
		position: relative;
		top: -0.4rem;
		right: -2rem;
		z-index: -1;
	}
`

const OpeningSection = () => (
	<StaticQuery
		query={graphql`
			query {
				allFile(filter: { name: { regex: "/opening/" } }) {
					edges {
						node {
							childSectionsYaml {
								headline
								openingParagraphs
							}
						}
					}
				}
			}
		`}
		render={firstEdge(
			({ childSectionsYaml: { headline, openingParagraphs } }) => (
				<S.Wrapper>
					<S.PhotoHeadlineWrapper>
						<ProfilPhoto />
						<Headline revert centered>
							{headline}
						</Headline>
					</S.PhotoHeadlineWrapper>
					<SeparatedParagraphs centered paragraphs={openingParagraphs} />
				</S.Wrapper>
			)
		)}
	/>
)

OpeningSection.propTypes = {
	data: P.shape({})
}

export default OpeningSection
