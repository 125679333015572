import React from 'react'
import P from 'prop-types'
import styled from 'styled-components'

import flex from 'utils/style/flex'
import { ExternalLink, Paragraph, Separator, Headline, Arrow } from 'atoms'
import SOCIAL_MEDIA_URLS from 'constants/socialMediaURLs'

const S = {}

S.Wrapper = styled.section`
	${flex.col.center};
	height: 100%;
`

S.Adress = Paragraph.withComponent('address')

const ContactSection = ({ email }) => (
	<S.Wrapper>
		<Headline centered revert>
			Me contacter ?
		</Headline>
		<Separator />
		<S.Adress centered>
			<ExternalLink href={`mailto:${email}`}>Envoyez moi un email</ExternalLink>
			, un <ExternalLink href={SOCIAL_MEDIA_URLS.twitter}>tweet</ExternalLink>{' '}
			ou bien...
		</S.Adress>
		<Arrow />
	</S.Wrapper>
)

ContactSection.propTypes = {
	email: P.string.isRequired
}
export default ContactSection
