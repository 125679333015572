import React, { Component, Fragment } from 'react'
import P from 'prop-types'

function * cycleGenerator (it, maxCycles = Infinity) {
	let currCycle = 0
	while (currCycle < maxCycles) {
		if (currCycle === maxCycles) break
		yield * it
		currCycle++
	}
}

class Cycle extends Component {
	constructor (props) {
		super(props)
		this.cycle = cycleGenerator(this.props.items)
		const currentItems = this.getNextCycle()
		this.state = {
			currentItems
		}
	}

	getNextCycle = () => {
		const nextCycleItems = []
		while (nextCycleItems.length <= this.props.perCycle - 1) {
			nextCycleItems.push(this.cycle.next().value)
		}
		return nextCycleItems
	}

	handleNext = () =>
		this.setState(s => ({
			...s,
			currentItems: this.getNextCycle()
		}))

	render () {
		return (
			<Fragment>
				{this.props.children({
					currentItems: this.state.currentItems,
					handleNext: this.handleNext
				})}
			</Fragment>
		)
	}
}

Cycle.propTypes = {
	children: P.func.isRequired,
	perCycle: P.number.isRequired,
	items: P.array.isRequired
}

export default Cycle
