import { css } from 'styled-components'
const flex = {
	col: {
		center: css`
			display: flex;
			flex-direction: column;
			align-items: center;
		`
	}
}
export default flex
