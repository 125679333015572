import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import P from 'prop-types'
import styled from 'styled-components'
import tm from 'themmer'

import { aliasedFlattenEdges, objArrUnionByProp } from 'utils/gatsby'
import { StackItem, Cycle } from 'molecules'
import { Headline, Button } from 'atoms'
import flex from 'utils/style/flex'

const S = {}
S.Wrapper = styled.section`
	${flex.col.center};
	align-items: stretch;
	padding-top: ${tm`spacing.lg`};

	${Button} {
		align-self: center;
	}
`
S.StackItemsList = styled.ul`
	display: flex;
	justify-content: space-around;
	margin: 0;
	padding: 0;
	flex-wrap: wrap;
`

const StackSection = () => (
	<StaticQuery
		query={graphql`
			query {
				infos: allFile(filter: { name: { regex: "/stack/" } }) {
					edges {
						node {
							id
							childSectionsYaml {
								stackItems {
									name
									label
									description
								}
							}
						}
					}
				}

				logos: allFile(filter: { dir: { regex: "/stackLogos/" } }) {
					edges {
						node {
							id
							name
							childImageSharp {
								fluid(maxWidth: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		`}
		render={aliasedFlattenEdges(
			({
				infos: {
					childSectionsYaml: { stackItems }
				},
				logos
			}) => {
				const items = objArrUnionByProp('name')(stackItems, logos)

				return (
					<S.Wrapper>
						<Headline centered>Stack</Headline>
						<Cycle perCycle={2} items={items}>
							{({ handleNext, currentItems }) => (
								<Fragment>
									<S.StackItemsList>
										{currentItems.map(stackItem => (
											<StackItem key={stackItem.id} {...stackItem} />
										))}
									</S.StackItemsList>
									<Button sm secondary onClick={handleNext}>
										Suivant
									</Button>
								</Fragment>
							)}
						</Cycle>
					</S.Wrapper>
				)
			}
		)}
	/>
)

StackSection.propTypes = {
	data: P.shape({})
}

export default StackSection
