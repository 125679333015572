import { keyframes, css } from 'styled-components'

const fadeInDownKF = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -8%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }`

const fadeInUpKF = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, .5%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }`
export const fadeInDown = css`
	animation: ${fadeInDownKF} 0.7s ease-in-out;
`
export const fadeInUp = css`
	animation: ${fadeInUpKF} 0.7s ease-in-out;
`
