import React from 'react'
import P from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'
import tm from 'themmer'

import { Headline } from 'atoms'
import { SeparatedParagraphs } from 'molecules'
import flex from 'utils/style/flex'
import { fadeInDown } from 'utils/style/keyframes'
const S = {}

S.Wrapper = styled.article`
	${flex.col.center};
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: ${tm`spacing.lg`};
`
S.Name = Headline.withComponent('a')

S.Infos = styled.div`
	${flex.col.center};
`
S.Image = styled(Img)`
	width: 16rem;
	border-radius: ${tm`radii.lg`};
	transition: ${tm`transition.md`};
	height: auto;
	${fadeInDown};
`
const PortfolioItem = ({
	name,
	description,
	childImageSharp: image,
	builtWith,
	link
}) => {
	return (
		<S.Wrapper>
			<S.Infos>
				<S.Name revert href={link} target="_blank" rel="noreferrer noopener">
					{name}
				</S.Name>
				<SeparatedParagraphs centered paragraphs={[description, builtWith]} />
			</S.Infos>
			<a href={link} target="_blank" rel="noreferrer noopener">
				<S.Image {...image} />
			</a>
		</S.Wrapper>
	)
}

PortfolioItem.propTypes = {
	name: P.string.isRequired,
	description: P.string.isRequired,
	link: P.string.isRequired,
	builtWith: P.string.isRequired,
	childImageSharp: P.shape({
		fluid: P.shape({})
	}).isRequired
}

export default PortfolioItem
