const socialMediaUrls = (profilName, other = {}) => ({
	twitter: `https://twitter.com/${profilName}`,
	medium: `https://medium.com/@${profilName}`,
	github: `https://github.com/${profilName}`,
	npm: `https://www.npmjs.com/~${profilName}`,
	...other
})

const SOCIAL_MEDIA_URLS = socialMediaUrls('elcoosp', {
	linkedIn: 'https://fr.linkedin.com/in/louis-cospain-a71464169'
})

export default SOCIAL_MEDIA_URLS
