import React from 'react'
import styled from 'styled-components'
import P from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Headline } from 'atoms'
import { PortfolioItem } from 'molecules'
import flex from 'utils/style/flex'
import { aliasedFlattenEdges, objArrUnionByProp } from 'utils/gatsby'

const S = {}
S.Wrapper = styled.section`
	${flex.col.center};
	justify-content: space-around;
`

const PortfolioSection = () => (
	<StaticQuery
		query={graphql`
			query {
				infos: allFile(filter: { name: { regex: "/portfolio/" } }) {
					edges {
						node {
							id
							childSectionsYaml {
								portfolioItems {
									name
									link
									builtWith
									description
								}
							}
						}
					}
				}

				images: allFile(filter: { dir: { regex: "/portfolioImages/" } }) {
					edges {
						node {
							id
							name
							childImageSharp {
								fluid(maxWidth: 500, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		`}
		render={aliasedFlattenEdges(
			({
				infos: {
					childSectionsYaml: { portfolioItems }
				},
				images
			}) => {
				const items = objArrUnionByProp('name')(
					portfolioItems,
					Array.isArray(images) ? images : [images]
				)

				// Ajouter headline quand il y auras plusieurs app
				return (
					<S.Wrapper>
						{/* <Headline centered>Some apps I made</Headline> */}
						{items.map(i => (
							<PortfolioItem key={i.id} {...i} />
						))}
					</S.Wrapper>
				)
			}
		)}
	/>
)

PortfolioSection.propTypes = {
	data: P.shape({})
}
export default PortfolioSection
