import React from 'react'
import styled, { withTheme } from 'styled-components'
const Arrow = withTheme(({ theme }) => (
	<svg
		width="90px"
		height="20rem"
		viewBox="0 0 90 416"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<desc>Created with Sketch.</desc>
		<defs>
			<linearGradient
				x1="0%"
				y1="0%"
				x2="101.999998%"
				y2="100.999999%"
				id="linearGradient-1"
			>
				<stop stopColor={theme.color.sd} offset="0%" />
				<stop stopColor={theme.color.pm} offset="100%" />
			</linearGradient>
		</defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="Desktop"
				transform="translate(-338.000000, -43.000000)"
				stroke="url(#linearGradient-1)"
			>
				<g id="Arrow" transform="translate(339.000000, 44.000000)">
					<g>
						<path
							d="M42.2767064,0 C42.2767064,0 -12.3166138,7.96564363 2.57300293,89.1887268 C17.4626197,170.41181 81.3807515,190.548157 86.7077184,242.371838 C92.0346852,294.195519 44.2170265,317.475473 28.789555,344.237852 C18.5045739,362.079439 18.5045739,381.485121 28.789555,402.454898"
							id="Path-2"
							strokeWidth="2"
						/>
						<polyline
							id="Path-3"
							strokeWidth="2"
							transform="translate(28.319695, 399.319695) scale(1, -1) rotate(158.000000) translate(-28.319695, -399.319695) "
							points="17.3196949 388.319695 17.3196949 410.319695 39.3196949 410.319695"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
))

export default styled(Arrow)`
	display: block;
`
