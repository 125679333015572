import React from 'react'
import P from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled, { injectGlobal, ThemeProvider } from 'styled-components'
import styledNormalize from 'styled-normalize'
import tm from 'themmer'

import THEME from 'constants/theme'
import typography from 'utils/style/typography'
import scrollbar from 'utils/style/scrollbar'
import reset from 'utils/style/reset'
import { Header, Footer } from 'molecules'
import { HEADER_HEIGHT } from 'src/components/molecules/Header'
import { fadeInUp } from 'utils/style/keyframes'

injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700');

  ${styledNormalize}
  ${typography}
  ${reset}
  ${scrollbar}
`

const S = {}

S.MainFooterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: calc(100vh - ${HEADER_HEIGHT});
	${fadeInUp};
`
S.Main = styled.main`
	padding: ${tm`spacing.lg`};
`
const Layout = ({ children, data }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={data => (
			<ThemeProvider theme={THEME}>
				<>
					<Helmet
						title={data.site.siteMetadata.title}
						meta={[
							{
								name: 'description',
								content: 'Portfolio de Louis Cospain, développeur web'
							},
							{
								name: 'keywords',
								content:
									'portfolio, cospain, louis cospain, javascript, web design, front-end'
							}
						]}
					>
						<html lang="fr" />
					</Helmet>
					<Header />
					<S.MainFooterWrapper>
						<S.Main>{children}</S.Main>
						<Footer />
					</S.MainFooterWrapper>
				</>
			</ThemeProvider>
		)}
	/>
)

Layout.propTypes = {
	children: P.node.isRequired,
	data: P.shape({})
}

export default Layout
