import React from 'react'
import styled from 'styled-components'
import tm from 'themmer'
import {
	faTwitter,
	faGithub,
	faMedium,
	faNpm
} from '@fortawesome/free-brands-svg-icons'

import SOCIAL_MEDIA_URLS from 'constants/socialMediaURLs'
import flex from 'utils/style/flex'
import { Shape, Icon } from 'atoms'
import { fadeInDown } from 'utils/style/keyframes'

const S = {}
S.Wrapper = styled.footer`
	${flex.col.center};
	position: relative;
`
/* Some nasty hack to be less spaced from the Shape */
S.SocialMediasList = styled.nav`
	padding: ${tm`spacing.md`};
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	${fadeInDown};
`

const Footer = () => (
	<S.Wrapper>
		<S.SocialMediasList>
			<Icon href={SOCIAL_MEDIA_URLS.github} faIcon={faGithub} />
			<Icon href={SOCIAL_MEDIA_URLS.medium} faIcon={faMedium} />
			<Icon href={SOCIAL_MEDIA_URLS.npm} faIcon={faNpm} />
			<Icon href={SOCIAL_MEDIA_URLS.twitter} faIcon={faTwitter} />
		</S.SocialMediasList>
		<Shape />
	</S.Wrapper>
)

export default styled(Footer)``
