// Flatten the edges nodes in an array or inlne it if only one
const nodesFromEdges = (data, nodes = data.edges.map(({ node }) => node)) =>
	nodes.length > 1 ? nodes : nodes[0]
// Both utils extract data FROM THE FIRST KEY MET in the data object
// Then execute the renderFunction with the extracted data
export const flattenEdges = renderFunction => data =>
	renderFunction(nodesFromEdges(Object.values(data)[0]))

export const firstEdge = renderFunction => data =>
	renderFunction(Object.values(data)[0].edges[0].node)

export const aliasedFlattenEdges = renderFunction => data =>
	renderFunction(
		Object.entries(data).reduce((acc, [alias, data]) => {
			acc[alias] = nodesFromEdges(data)
			return acc
		}, {})
	)

export const objArrUnionByProp = prop => (firstArr, secondArr) =>
	firstArr.map(obj => ({
		...obj,
		...secondArr.filter(o => o[prop] === obj[prop])[0]
	}))
