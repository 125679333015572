import styled, { css } from 'styled-components'
import P from 'prop-types'
import tm from 'themmer'

import flex from 'utils/style/flex'
import { hover, focusOutline } from 'utils/style/prop'

const Button = styled.button`
	padding: ${p => (p.sm ? tm`spacing.xs` : tm`spacing.sm`)};
	border: none;
	${p =>
		p.secondary
			? css`
					color: ${tm`color.pm`};
					background-color: ${tm`color.white`};
			  `
			: css`
					color: ${tm`color.white`};
					background-image: ${tm`gradient.pm`};
			  `};
	font-size: ${p => (p.sm ? tm`font.size.sm` : tm`font.size.md`)};
	font-weight: 400;
	font-style: italic;
	border-radius: ${tm`radii.md`};
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	${flex.col.center};
	flex-direction: row;
	justify-content: center;
	${hover.shadow()};
	${hover.transition()};
	${focusOutline};
`

Button.propTypes = {
	secondary: P.bool,
	sm: P.bool
}

export default Button
