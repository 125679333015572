import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tm from 'themmer'

import flex from 'utils/style/flex'
import { hover, focusOutline } from 'utils/style/prop'

const multRem = multiplicator => themeGetter => props =>
	`${parseInt(themeGetter(props)) * multiplicator}rem`

const iconSize = multRem(1.5)(tm`font.size.xl`)

const S = {}
S.IconLink = styled.a`
	${flex.col.center};
	justify-content: center;
	font-size: ${tm`font.size.lg`};
	height: ${iconSize};
	width: ${iconSize};
	margin: ${tm`spacing.sm`};
	color: ${tm`color.white`};
	background-image: ${tm`gradient.pm`};
	border-radius: ${tm`radii.rounded`};
	box-shadow: ${tm`shadow.md`};
	${hover.transition()};
	&:hover {
		box-shadow: ${tm`shadow.lg`};
	}
	${focusOutline};
`

const Icon = ({ faIcon, ...props }) => (
	<S.IconLink {...props} target="_blank" rel="noopener">
		<FontAwesomeIcon icon={faIcon} />
	</S.IconLink>
)

export default Icon
