import { transparentize } from 'polished'
/*
pm: primary
sd: secondary
ti: tertiary
*/
const color = {
	white: 'rgba(255, 255, 255, 0.95)',
	black: '#051923',
	pm: '#00d2ff',
	sd: '#3a7bd5',
	ti: '#1a2a6c'
}

const GRADIENT_DIRECTION = 'to right top'

const makeLinearGradient = (left, right) =>
	`linear-gradient(${GRADIENT_DIRECTION}, ${color[left]}, ${color[right]})`

const gradient = {
	pm: makeLinearGradient('pm', 'sd'),
	sd: makeLinearGradient('ti', 'black'),
	ti: makeLinearGradient('pm', 'black')
}

// /*
// sm: small
// md: medium
// lg: large
// */
const lowOpacity = transparentize(0.7)
const shadow = {
	sm: `0px 1px 5px ${lowOpacity(color.pm)}`,
	md: `0px 3px 9px ${lowOpacity(color.pm)}`,
	lg: `0px 14px 40px ${lowOpacity(color.pm)}`
}
const spacing = {
	xs: '.7rem',
	sm: '1rem',
	md: '1.4rem',
	lg: '2.2rem',
	xl: '3rem'
}

const easing = 'cubic-bezier(0.540, 0.155, 0.000, 1.040)'
const transition = {
	sm: `all .3s ${easing}`,
	md: `all .5s ${easing}`,
	lg: `all .7s ${easing}`
}

const radii = {
	sm: '4px',
	md: '8px',
	lg: '16px',
	rounded: '50%'
}
const border = {
	sm: `1px solid ${color.pm}`,
	md: `2px solid ${color.pm}`,
	lg: `4px solid ${color.pm}`
}
const font = {
	size: {
		sm: '1rem',
		md: '1.2rem',
		lg: '1.6rem',
		xl: '2.4rem'
	}
}
const slope = {
	sm: '-1deg',
	md: '-2deg',
	lg: '-4deg'
}
const THEME = {
	color,
	border,
	radii,
	gradient,
	shadow,
	spacing,
	font,
	slope,
	transition
}

export default THEME
