import React from 'react'
import P from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import tm from 'themmer'
import { transparentize } from 'polished'

import { hover } from 'utils/style/prop'
import flex from 'utils/style/flex'
import Img from 'gatsby-image'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const S = {}

S.Wrapper = styled.li`
	list-style: none;
	flex: 1;
	min-width: 10rem;
	max-width: 17rem;
	min-height: 10rem;
	margin: ${tm`spacing.lg`};

	animation: ${fadeIn} 0.5s ease-out;
	border: 1px solid ${p => transparentize(0.8)(p.theme.color.pm)};
	border-radius: ${tm`radii.md`};
	${hover.transition()};
	&:hover {
		box-shadow: ${tm`shadow.sm`};
	}
`

S.Name = styled.h2`
	font-size: ${tm`font.size.md`};
	text-align: center;
	margin: ${tm`spacing.xs`};
	&::after {
		content: '';
		display: block;
		margin-top: 0.3rem;
		height: 3px;
		width: 100%;
		background-image: ${tm`gradient.pm`};
		transform: rotate(${tm`slope.md`});
	}
`
const logoPositionFromRemWidth = r => css`
	width: ${r}rem;
	height: auto;
	left: -${r / 2}rem;
	top: -${r / 2}rem;
`

const LOGO_REM_SIZE = 4
S.Logo = styled(Img)`
	${logoPositionFromRemWidth(LOGO_REM_SIZE)};
	${hover.transition()};
	animation: ${fadeIn} 1s ease-out;
	filter: drop-shadow(${tm`shadow.sm`});

	${S.Wrapper}:hover & {
		filter: drop-shadow(${tm`shadow.md`});
	}
`

S.InfosWrapper = styled.article`
	${flex.col.center};
	justify-content: center;
	margin-top: -${LOGO_REM_SIZE}rem;
	height: 100%;
`

S.Description = styled.p`
	margin: ${tm`spacing.xs`};
	font-size: ${tm`font.size.sm`};
	text-align: center;
`

const StackItem = ({ name, description, childImageSharp: logo, label }) => (
	<S.Wrapper>
		{logo && <S.Logo {...logo} />}
		<S.InfosWrapper>
			<S.Name>{label || name}</S.Name>
			<S.Description>{description}</S.Description>
		</S.InfosWrapper>
	</S.Wrapper>
)

StackItem.propTypes = {
	name: P.string.isRequired,
	description: P.string.isRequired,
	childImageSharp: P.shape({
		fluid: P.shape({})
	}).isRequired
}

export default StackItem
