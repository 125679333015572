import React from 'react'
import styled, { withTheme } from 'styled-components'
const Shape = withTheme(({ theme }) => (
	<svg
		width="100%"
		viewBox="0 0 595 46"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<desc>Created with Sketch.</desc>
		<defs>
			<linearGradient x1="0%" y1="100%" x2="100%" y2="0%" id="linearGradient-1">
				<stop stopColor={theme.color.pm} offset="0%" />
				<stop stopColor={theme.color.sd} offset="100%" />
			</linearGradient>
		</defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="A4"
				transform="translate(0.000000, -796.000000)"
				fill="url(#linearGradient-1)"
			>
				<g id="Déco" transform="translate(-54.000000, 100.000000)">
					<path
						d="M54,701.758446 C115.234612,729.941158 197.637854,738.250517 301.209725,726.686525 C456.567531,709.340536 411.535259,712.857599 549.377068,701.758446 C641.271607,694.35901 674.479251,694.35901 649,701.758446 L649,742 L54,742 L54,701.758446 Z"
						id="Rectangle"
					/>
				</g>
			</g>
		</g>
	</svg>
))

export default styled(Shape)`
	display: block;
`
