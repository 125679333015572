import styled from 'styled-components'
import P from 'prop-types'
import tm from 'themmer'

import { ternary } from 'utils/style/prop'

const Headline = styled.h1`
	margin: 0;
	text-decoration: none;
	padding: ${tm`spacing.md`};
	font-size: ${tm`font.size.lg`};
	font-weight: 700;
	color: ${p => (p.revert ? tm`color.white` : tm`color.black`)};
	${ternary.centered};
	background-image: ${p => (p.revert ? tm`gradient.pm` : 'transparent')};
	transition: ${tm`transition.md`};
	clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
`

Headline.propTypes = {
	centered: P.bool,
	revert: P.bool
}

export default Headline
