import React from 'react'

import styled, { css } from 'styled-components'
import P from 'prop-types'
import Link from 'gatsby-link'
import tm from 'themmer'

import { hover, focusOutline } from 'utils/style/prop'

const S = {}

S.Wrapper = styled.li`
	padding: ${tm`spacing.xs`};
	${p =>
		p.minContent &&
		css`
			max-width: min-content;
		`};
	font-size: ${p => (p.lg ? tm`font.size.lg` : tm`font.size.md`)};
	font-weight: ${p => (p.lg ? 700 : 400)};
`

const activeClassName = 'activeMenuLink'
const hoverActive = css`
	border-bottom: 8px solid rgba(255, 255, 255, 0.3);
	text-shadow: 5px 5px ${tm`color.black`};
`

S.MenuLink = styled(Link).attrs({
	activeClassName
})`
	color: ${tm`color.white`};
	cursor: pointer;
	text-decoration: none;

	${hover.transition('sm', 'sm')};
	border-bottom: 0px solid rgba(255, 255, 255, 0.6);
	&:hover {
		${hoverActive};
	}

	&.${activeClassName} {
		${hoverActive};
	}

	${focusOutline};
`

const MenuItem = ({ children, to, ...props }) => (
	<S.Wrapper {...props} activeClassName={activeClassName}>
		<S.MenuLink to={to} activeClassName={activeClassName}>
			{children}
		</S.MenuLink>
	</S.Wrapper>
)

MenuItem.propTypes = {
	lg: P.bool,
	minContent: P.bool
}

// To be able to reference the component in other styled ones
export default styled(MenuItem)``
