import styled from 'styled-components'
import tm from 'themmer'

const Separator = styled.hr`
  border: none;
  margin: ${tm`spacing.xs`};
  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 5rem;
    background-image: ${tm`gradient.pm`};
    opacity: 0.2;
    transform: rotate(${tm`slope.md`});
  }
`

export default Separator
