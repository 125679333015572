import { css } from 'styled-components'
const reset = css`
	html {
		min-height: 100%;
	}

	body {
		overflow-x: hidden;
		height: 100%;
		width: 100vw;
		scroll-behavior: smooth;
		line-height: 1.5;
	}

	#___gatsby {
		height: 100%;
	}

	#___gatsby > div:first-child {
		height: 100%;
	}
`

export default reset
