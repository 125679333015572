import React from 'react'
import P from 'prop-types'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import tm from 'themmer'

import { firstEdge } from 'utils/gatsby'
import { hover } from 'utils/style/prop'
import { fadeInDown } from 'utils/style/keyframes'

const S = {}
S.Figure = styled.figure`
	width: 10rem;
	max-width: 500px;

	margin: 0;
	overflow: hidden;
	box-shadow: ${tm`shadow.lg`}, 0.6rem 0.6rem ${tm`color.black`};
	${hover.transition('lg', 'lg')};
	${fadeInDown};
	&:hover {
		box-shadow: ${tm`shadow.lg`}, -0.6rem -0.6rem ${tm`color.black`};
	}
`

const ProfilPhoto = () => (
	<StaticQuery
		query={graphql`
			query {
				allImageSharp(
					filter: { original: { src: { regex: "/profil-photo/" } } }
				) {
					edges {
						node {
							fluid(maxWidth: 900) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		`}
		render={firstEdge(({ fluid }) => (
			<S.Figure>
				<Img fluid={fluid} />
			</S.Figure>
		))}
	/>
)

ProfilPhoto.propTypes = {
	data: P.shape({})
}

export default ProfilPhoto
