import React, { Fragment } from 'react'
import P from 'prop-types'

import { Separator, Paragraph } from 'atoms'

const isNotLast = (i, a) => i !== a.length - 1
const SeparatedParagraphs = ({ paragraphs, ...props }) => (
	<Fragment>
		{paragraphs.map((p, i, a) => (
			<Fragment key={p}>
				<Paragraph {...props}>{p}</Paragraph>
				{isNotLast(i, a) && <Separator />}
			</Fragment>
		))}
	</Fragment>
)

SeparatedParagraphs.propTypes = {
	paragraphs: P.arrayOf(P.string).isRequired
}

export default SeparatedParagraphs
