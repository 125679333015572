import styled from 'styled-components'
import P from 'prop-types'
import tm from 'themmer'

import { ternary, hover } from 'utils/style/prop'

const Paragraph = styled.p`
	margin: 0;
	max-width: 31rem;
	font-size: ${tm`font.size.md`};
	font-style: normal;
	font-weight: 400;
	color: ${tm`color.black`};
	${hover.transition()};
	${ternary.centered};
	padding: ${tm`spacing.sm`};
`

Paragraph.propTypes = {
	centered: P.bool
}

export default Paragraph
